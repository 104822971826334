import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import bg from "./bg/signin.svg";
import bgimg from "./bg/backimg.jpg";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAppStore from "./store/appStore";
import axios from "axios";
import CreditScoreIcon from '@mui/icons-material/CreditScore';

const darkTheme = createTheme({
    palette: {
        mode: "dark",
    },
});

const boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "70%",
    bgcolor: "background.paper",
    boxShadow: 24,
};

const center = {
    position: "relative",
    top: "50%",
    left: "37%",
};

export default function Login() {
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const { updateUsername, updatePassword, updateToken, updateUserType, updateUserId, updateUserEmail, token } = useAppStore();
    const baseURL = process.env.REACT_APP_API_URL;
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        // console.log('token ', token);
        if (token) {
            navigate("/home");
        }
    }, [token]);

    const handleSubmit = async (event) => {
        setDisabled(true);
        event.preventDefault();

        let posturl = baseURL + "login";

        await axios
            .post(posturl, {
                email: name,
                password: password,
            })
            .then(function (res) {
                console.log(res.data);
                updateToken(res.data.token);
                updateUsername(res.data.user.name);
                updateUserEmail(res.data.user.email);
                updatePassword(password);
                updateUserType(res.data.user.type);
                updateUserId(res.data.user.id);
                setDisabled(false);
                // navigate("/home");
            })
            .catch(function (error) {
                console.log('Error ', error);
                setDisabled(false);
                console.log("kcheckpost" + error); //return 429
            });
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${bgimg})`,
                    backgroundSize: "cover",
                    minHeight: "100vh", // Use minHeight to ensure full screen height
                    color: "#f5f5f5",
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "100%", // Use 100% width to make it responsive
                        maxWidth: "800px", // Limit the maximum width for larger screens
                        bgcolor: "background.paper",
                        boxShadow: 24,
                    }}
                >
                    <Grid container>
                        <Grid item xs={12} sm={12} lg={6}>
                            <div className="flex justify-center mt-32">
                                <div>
                                    <div className="text-[30px] font-semibold text-blue-600 text-center">
                                        CDOT LMS
                                    </div>
                                    <CreditScoreIcon className="text-blue-600" sx={{ fontSize: 240 }} />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Box
                                style={{
                                    backgroundSize: "cover",
                                    minHeight: "80vh", // Use minHeight for responsive height
                                    backgroundColor: "#3b33d5",
                                }}
                            >
                                <ThemeProvider theme={darkTheme}>
                                    <div className="flex justify-center pt-16">
                                        <div>
                                        </div>
                                    </div>
                                    <Container>
                                        <Box height={35} />
                                        <div className="flex justify-center">
                                            <div>
                                                <Typography component="h1" variant="h6">
                                                    <div className="mt-20">Sign In</div>
                                                </Typography>
                                            </div>
                                        </div>

                                        <Box
                                            component="form"
                                            noValidate
                                            onSubmit={handleSubmit}
                                            sx={{ mt: 2 }}
                                        >
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        id="email"
                                                        label="Username"
                                                        value={name}
                                                        onChange={handleNameChange}
                                                        name="email"
                                                        size="small"
                                                        autoComplete="email"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sx={{ ml: "3em", mr: "3em", mt: "10px" }}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        name="password"
                                                        label="Password"
                                                        value={password}
                                                        onChange={handlePasswordChange}
                                                        type="password"
                                                        size="small"
                                                        id="password"
                                                        autoComplete="new-password"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                                                    {disabled ? (
                                                        <Button
                                                            variant="contained"
                                                            size="large"
                                                            disabled
                                                            fullWidth={true}
                                                            sx={{
                                                                mt: "10px",
                                                                mr: "20px",
                                                                borderRadius: 28,
                                                                color: "#ffffff",
                                                                minWidth: "170px",
                                                                backgroundColor: "#FF9A01",
                                                            }}
                                                        >
                                                            Sign in
                                                            <span className="mb-1 -mr-4 ml-3">
                                                                <svg ariaHidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                </svg>
                                                            </span>
                                                        </Button>
                                                    ) :
                                                        (
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                size="large"
                                                                fullWidth={true}
                                                                sx={{
                                                                    mt: "10px",
                                                                    mr: "20px",
                                                                    borderRadius: 28,
                                                                    color: "#ffffff",
                                                                    minWidth: "170px",
                                                                    backgroundColor: "#FF9A01",
                                                                }}
                                                            >
                                                                Sign in
                                                            </Button>
                                                        )
                                                    }

                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Container>
                                </ThemeProvider>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>
    );
}
