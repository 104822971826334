import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import useAppStore from "../store/appStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TableView from "../components/tableview";
import jsonData from '../../src/iform.json';
import LoanDrawer from "./loanDrawer";
import { CSVDownload } from "react-csv";


export default function LoanList() {
    const baseURL = process.env.REACT_APP_API_URL;
    const { token, loanList, updateLoanList, userType, updateIsRecoveryDrawer } = useAppStore();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const masterData = jsonData[0]?.loan;
    const [searchQuery, setSearchQuery] = useState("");
    const [emprecord, SetEmprecord] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isDownload, setIsDownload] = useState(false);

    const handleFilter = () => {
        // console.log('Filtered Start Date ', startDate);
        // console.log('Filtered End Date ', endDate);

        const filtered = emprecord.filter(item => {
            const createdAt = new Date(item.created_at);
            return createdAt >= new Date(startDate) && createdAt <= new Date(endDate);
        });

        // console.log('Filtered filtered ', filtered);

        SetEmprecord(filtered);
    };

    const handleExport = () => {
        setIsDownload(true);
    };

    useEffect(() => {
        if (isDownload) {
            setIsDownload(false);
        }
    }, [isDownload]);

    useEffect(() => {
        searchData(searchQuery);
    }, [searchQuery])

    const searchData = (searchQuery) => {
        let filteredData = loanList;
        if (searchQuery) {
            filteredData = loanList.filter(usr =>
                // usr?.agent_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                usr?.koid?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                // usr?.sol?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                usr?.account_no?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                usr?.status?.toLowerCase().includes(searchQuery.toLowerCase())
            )
            // console.log('searchQuery ', filteredData);
            SetEmprecord(filteredData);
        } else {
            SetEmprecord(loanList);
        }

    }

    useEffect(() => {
        // console.log('loanList ', loanList);
        if (loanList) {
            SetEmprecord(loanList);
        }
    }, [loanList]);

    useEffect(() => {
        if (!token) {
            navigate("/");
        }

        if (userType == "Admin") {
            getUsers();
        }
    }, []);

    const handleRefresh = () => {
        setSearchQuery("");
        setLoading(true);
        getUsers();
    }

    const getUsers = async () => {
        let posturl = baseURL + "loan-recovery";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateLoanList(res.data);
                SetEmprecord(res.data);
                // console.log('Rows ', res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleOpenForm = () => {
        updateIsRecoveryDrawer(true);
    }

    const TotalAmountCollected = ({ data }) => {
        const totalAmount = data.reduce((sum, record) => {
            return sum + parseFloat(record.amount_collected);
        }, 0);

        return (
            <div>
                <h1>Total Amount Collected:
                    <span className="font-semibold ml-2">₹{totalAmount.toFixed(2)}</span>
                </h1>
            </div>
        );
    };

    return (
        <>
            {isDownload && (
                <>
                    <CSVDownload data={emprecord} target="_blank" />;
                </>
            )}
            <LoanDrawer />
            <div className="ml-2 mr-2">
                <div className="flex justify-between mb-3 mt-5">
                    <div className="text-lg">
                        Loan Recovery
                    </div>


                    <div>
                        <div className="flex justify-end -mb-4">
                            <div className="mr-2 -mt-1">
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Enter KOID or Account"
                                        value={searchQuery}
                                        onChange={(e) => (setSearchQuery(e.target.value))}
                                        className="rounded-full py-1 px-4 border border-solid border-gray-400"
                                    />
                                </div>
                            </div>
                            <div>
                                <button
                                    className="pulsebuttonblue px-3 py-1 mr-1"
                                    onClick={() => handleOpenForm()}
                                >
                                    <AddCircleIcon className="w-5 h-5" />
                                    <span>Add Recovery</span>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="flex justify-between mb-5 mt-5">
                    <div className="flex">
                        <div className="mr-7">
                            <label className="block text-gray-700 text-sm font-bold">Start Date:</label>
                            <input
                                type="date"
                                className="border rounded w-full px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>

                        <div className="mr-7">
                            <label className="block text-gray-700 text-sm font-bold">End Date:</label>
                            <input
                                type="date"
                                className="border rounded w-full px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>

                        <button
                            className="pulsebuttonblue px-3 py-1 mr-1 mt-4"
                            onClick={handleFilter}
                        >
                            Filter
                        </button>
                    </div>

                    <div className="flex">
                        <button
                            className="pulsebuttonwhite px-3 py-1 mr-3 mt-4"
                            onClick={() => handleRefresh()}
                        >
                            Refresh
                        </button>
                        <button
                            className="pulsebuttonblue px-3 py-1 mr-1 mt-4"
                            onClick={handleExport}
                        >
                            Export
                        </button>
                    </div>

                </div>
                <Divider />
                <Box height={10} />
                {!loading ? (
                    <div className="mt-5">
                        <div className="mb-1">
                            <TotalAmountCollected data={emprecord} />
                        </div>
                        <TableView rows={emprecord} from="loan" masterData={masterData} />
                    </div>
                ) : (
                    <>
                        <Paper
                            sx={{
                                width: "98%",
                                overflow: "hidden",
                                padding: "12px",
                            }}
                        >
                            <Box height={20} />
                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={30}
                            />
                            <Box height={40} />
                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={60}
                            />
                            <Box height={20} />
                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={60}
                            />
                            <Box height={20} />
                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={60}
                            />
                            <Box height={20} />
                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={60}
                            />
                            <Box height={20} />
                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={60}
                            />
                            <Box height={20} />
                        </Paper>
                    </>
                )}
            </div>
        </>
    );
}
