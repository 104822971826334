"use client";
import React, { useState, useEffect } from "react";
import {
    GridRowModes,
    DataGrid,
    GridToolbar,
    GridActionsCellItem,
    GridRowEditStopReasons,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import useAppStore from "../store/appStore";
import { generateDynamicColumns } from './columnUtils';


export default function TableView({ rows, from, masterData }) {
    const baseURL = process.env.REACT_APP_API_URL;
    const mainURL = process.env.REACT_APP_URL;
    const { token, updateMaster, updateIsMasterDrawer, updateFormData } = useAppStore();
    const { updateRowSelection } = useAppStore();
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleDeleteClick = (id) => {
        // console.log('handleDeleteClick ', id);
        deleteRecord(id);
    };

    const handleEditClick = (id) => {
        console.log('handleEditClick ', id);
        updateIsMasterDrawer(true);
        // setRows(rows.filter((row) => row.id !== id));
    };

    useEffect(() => {
        let dateFormat = "dd-MM-yyyy";
        let dynamicColumn = [];

        if (from === "master") {
            dynamicColumn = generateDynamicColumns(masterData, 'email', dateFormat);
        }
        if (from === "loan") {
            dynamicColumn = generateDynamicColumns(masterData, 'status', dateFormat);
        }

        dynamicColumn.push({
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    // <GridActionsCellItem
                    //     icon={<EditIcon />}
                    //     label="Edit"
                    //     className="textPrimary"
                    //     onClick={() => handleEditClick(id)}
                    //     color="success"
                    // />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => handleDeleteClick(id)}
                        color="warning"
                    />,
                ];
            },
        });

        setColumns(dynamicColumn);
        setLoading(false);
    }, []);

    const getUsers = async () => {
        let fromUrl = ""
        if (from === "master") {
            fromUrl = "master";
        } else {
            fromUrl = "loan-recovery";
        }
        let posturl = baseURL + from;
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (from === "master") {
                    updateMaster(res.data);
                }
                // setRows(res.data);
                // console.log('Rows ', res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const deleteRecord = (id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'are you sure to delete record of Id ' + id + ' ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteRow(id)
                },
                {
                    label: 'No',
                }
            ]
        });
    };

    const deleteRow = (id) => {
        let fromUrl = ""
        if (from === "master") {
            fromUrl = "master";
        } else {
            fromUrl = "loan-recovery";
        }
        let config = {
            method: 'delete',
            url: baseURL + fromUrl + "/" + id,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                Authorization: `Bearer ${token}`,
            }
            // data: [rows?._id]
        };

        axios.request(config)
            .then(response => {
                toast.success('Record deleted successfully!', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    style: {
                        width: '380px',
                    },
                });
                // console.log('deleted response ', response);
                getUsers();
            })
            .catch(err => {
                console.log('Error ', err);
                toast.error(err, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    style: {
                        width: '380px',
                    },
                });
            })
    }

    const handleOnCellClick = (params) => {
        if (params.field == "user_id" ||
            params.field == "koid" ||
            params.field == "dra" ||
            params.field == "dra" ||
            params.field == "account_no"
        ) {
            console.log('params?.row ', params);
            updateFormData(params?.row);
            updateIsMasterDrawer(true);
        }
        if (params.field == "sub_district") {
            window.open(mainURL + 'public/img/blog/' + params?.row?.sub_district, '_blank');
        }
    };

    return (
        <div>
            {!loading && (
                <div style={{ height: '69vh', width: '80vw' }} className="bg-blue-50 rounded-lg">
                    <DataGrid
                        slots={{ toolbar: GridToolbar }}
                        rowHeight={45}
                        rows={rows}
                        columns={columns}
                        pagination
                        autoPageSize
                        checkboxSelection
                        disableRowSelectionOnClick
                        onCellClick={handleOnCellClick}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            updateRowSelection(newRowSelectionModel);
                        }}
                        getRowClassName={(params) => {
                            return 'bg-white';
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                    />
                </div>
            )}

        </div>
    )
}
