"use client";
import { useEffect, useState } from "react";
import Drawer from '@mui/material/Drawer';
import useAppStore from '../store/appStore';
import CreateEvent from "./createMaster";
import { ToastContainer } from 'react-toastify';

export default function MasterDrawer() {
    const { isMasterDrawer, updateIsMasterDrawer } = useAppStore();
    const [isOpenEvent, setIsOpenEvent] = useState(false);

    useEffect(() => {
        // console.log('isMasterDrawer ', isMasterDrawer);
        if (isMasterDrawer) {
            setIsOpenEvent(true);
        } else {
            setIsOpenEvent(false);
        }
    }, [isMasterDrawer])


    const toggleDrawer = () => (e) => {
        if (e.key === 'Escape') {
            updateIsMasterDrawer(false);
        }
    };

    return (
        <>
            <ToastContainer />
            <Drawer
                anchor='right'
                open={isOpenEvent}
                onClose={toggleDrawer()}
                PaperProps={{
                    sx: { width: "47%" },
                }}
            >
                <CreateEvent />
            </Drawer>
        </>
    )
}
