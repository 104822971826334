import { format } from 'date-fns';

export function generateDynamicColumns(data, fname) {
    // console.log('generateDynamicColumns ', data);
    const dynamicColumn = data?.map(field => {
        let cellClassName = '';
        let type = '';
        let filterable = true;
        let valueOptions = [];
        if (field.name === fname) {
            cellClassName = `font-semibold text-blue-500 cursor-pointer`;
        }
        if (field.name === 'sub_district') {
            cellClassName = `font-semibold text-blue-500 cursor-pointer`;
        }
        if (field.name === 'koid') {
            cellClassName = `font-semibold text-blue-500 cursor-pointer`;
        }
        if (field.name === 'dra') {
            cellClassName = `font-semibold text-blue-500 cursor-pointer`;
        }
        if (field.name === 'user_id') {
            cellClassName = `font-semibold text-blue-500 cursor-pointer`;
        }
        if (field.name === 'account_no') {
            cellClassName = `font-semibold text-blue-500 cursor-pointer`;
        }
        if (field.name === 'amount_disbursed') {
            cellClassName = `font-semibold text-green-600 cursor-pointer`;
        }
        if (field.name === 'outstanding_balance') {
            cellClassName = `font-semibold text-orange-400 cursor-pointer`;
        }
        if (field.name === 'due_date') {
            cellClassName = `font-semibold text-red-600 cursor-pointer`;
        }
        if (field.type === 'date') {
            type = 'date';
        }
        if (field.type === 'dropdown') {
            type = 'singleSelect';
            valueOptions = field.optionList;
        }
        return {
            field: field.name,
            headerName: field.label,
            width: 170,
            type: type,
            filterable: filterable,
            valueOptions: valueOptions,
            cellClassName: cellClassName,
            editable: field.editable,
            valueGetter: (value, row) => {
                return value;
            },
            valueFormatter: (value) => {
                if (field.type === "date") {
                    // valueFormatter.format(Number(value)),
                }

            },
            renderHeader: () => (
                <strong>
                    {field.label}
                </strong>
            ),
        };
    });
    let createdAt = {
        field: "created_at",
        headerName: "Created At",
        width: 170,
        valueGetter: (value, row) => {
            // return `${new Date(row?.createdAt).toLocaleString() || ''}`;
            return value;
        },
        valueFormatter: (value) => {
        },
        renderHeader: () => (
            <strong>
                Created At
            </strong>
        ),
    };
    let updatedAt = {
        field: "updated_at",
        headerName: "Updated At",
        width: 170,
        valueGetter: (value, row) => {
            // return `${new Date(row?.createdAt).toLocaleString() || ''}`;
            return value;
        },
        valueFormatter: (value) => {
        },
        renderHeader: () => (
            <strong>
                Updated At
            </strong>
        ),
    };
    let createdBy = {
        field: "createdBy",
        headerName: "created By",
        width: 170,
        renderHeader: () => (
            <strong>
                Created By
            </strong>
        ),
    };
    dynamicColumn.push(createdBy, createdAt, updatedAt);
    return dynamicColumn;
}
