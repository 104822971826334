import { create } from 'zustand'
import { persist, createJSONStorage }
  from 'zustand/middleware'

const useAppSessionStore = create(
  persist(
    (set, get) => ({
      userdata: {},
      token: '',
      isRecoveryDrawer: false,
      recoveryList: [],
      username: '',
      password: '',
      token: '',
      userType: '',
      userId: '',
      userEmail: '',
      dopen: true,
      users: [],
      master: [],
      isMasterDrawer: false,
      csvImportFormOpen: false,
      formData: [],
      loanList: [],
      rowSelection: [],
      updateRowSelection: (rowSelection) =>
        set({ rowSelection: rowSelection }),
      updateLoanList: (loanList) =>
        set({ loanList: loanList }),
      updateFormData: (formData) =>
        set({ formData: formData }),
      updateCsvImportFormOpen: (csvImportFormOpen) =>
        set({ csvImportFormOpen: csvImportFormOpen }),
      updateIsMasterDrawer: (isMasterDrawer) =>
        set({ isMasterDrawer: isMasterDrawer }),
      updateMaster: (master) =>
        set({ master: master }),
      updateUsers: (users) =>
        set({ users: users }),
      updateDopen: (dopen) =>
        set({ dopen: dopen }),
      updateUserEmail: (userEmail) =>
        set({ userEmail: userEmail }),
      updateUserId: (userId) =>
        set({ userId: userId }),
      updateUserType: (userType) =>
        set({ userType: userType }),
      updateToken: (token) =>
        set({ token: token }),
      updatePassword: (password) =>
        set({ password: password }),
      updateUsername: (username) =>
        set({ username: username }),
      updateIsRecoveryDrawer: (isRecoveryDrawer) =>
        set({ isRecoveryDrawer: isRecoveryDrawer }),
      updateRecoveryList: (recoveryList) =>
        set({ recoveryList: recoveryList }),
      updateToken: (token) =>
        set({ token: token }),
      updateUserData: (userdata) =>
        set({ userdata: userdata }),
    }),
    {
      name: 'cdot-recovery-local-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage),
      // (optional) by default, 'localStorage' is used
    },
  ),
)

export default useAppSessionStore;