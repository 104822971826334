"use client";
import { useEffect, useState } from "react";
import Drawer from '@mui/material/Drawer';
import useAppStore from '../../store/appStore';
import CsvUploader from "./CsvUploader";


export default function ImportCSVDrawer() {
    const [openDrawer, setIsOpenDrawer] = useState(false);
    const { csvImportFormOpen, updateCsvImportFormOpen } = useAppStore();

    useEffect(() => {
        if (csvImportFormOpen) {
            setIsOpenDrawer(true);
        } else {
            setIsOpenDrawer(false);
        }
    }, [csvImportFormOpen])

    const toggleDrawer = () => (event) => {
        if (event.key === 'Escape') {
            updateCsvImportFormOpen(false);
        }
    };

    return (
        <>
            <Drawer
                anchor='right'
                open={openDrawer}
                onClose={toggleDrawer()}
                PaperProps={{
                    sx: { width: "83.5%" },
                }}
            >
                <CsvUploader />
            </Drawer>
        </>
    )
}
