import { Routes, Route, HashRouter } from "react-router-dom";
import Home from "./pages/Home";
import User from "./pages/User";
import Login from "./Login";
import Loan from "./pages/Loan";
import Master from "./pages/Master";
import useAppStore from "./store/appStore";
import ForgotPassword from "./pages/ForgotPassword";
import './App.css';

export default function App() {
    const { userType } = useAppStore();

    return (
        <>
            <HashRouter>
                <Routes>
                    <Route path="/" exact element={<Login />}></Route>
                    <Route
                        path="/reset-password"
                        exact
                        element={<ForgotPassword />}
                    ></Route>
                    {userType === "Admin" && (
                        <>
                            <Route
                                path="/home"
                                exact
                                element={<Home />}
                            ></Route>
                            <Route
                                path="/master"
                                exact
                                element={<Master />}
                            ></Route>
                            <Route
                                path="/loan"
                                exact
                                element={<Loan />}
                            ></Route>
                            <Route
                                path="/user"
                                exact
                                element={<User />}
                            ></Route>
                        </>
                    )}
                </Routes>
            </HashRouter>
        </>
    );
}
