import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import useAppStore from "../../store/appStore";
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Divider from '@mui/material/Divider';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


const CsvUploader = () => {
    const hiddenFileInput = useRef(null);
    const [file, setFile] = useState(null);
    const [importFileName, setImportFileName] = useState(null);
    const { token, updateCsvImportFormOpen } = useAppStore();
    const baseURL = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(true);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, [])


    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        const fname = e.target.files[0].name;
        setImportFileName(fname);
    };

    const handleSubmit = async (e) => {
        setDisabled(true);
        if (!file) {
            toast.error('Please select a file', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                style: {
                    width: '380px',
                },
            });
            setDisabled(false);
            return;
        }

        const formData = new FormData();
        formData.append('csv_file', file);

        try {
            const response = await axios.post(baseURL + 'import-csv', formData, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });
            updateCsvImportFormOpen(false);
            toast.success(response.data.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                toastId: 'success',
                progress: undefined,
                theme: "light",
                style: {
                    width: '380px',
                },
            });
            setDisabled(false);
        } catch (error) {
            toast.error('An error occurred while uploading the file', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                style: {
                    width: '380px',
                },
            });
            setDisabled(false);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        console.log('handleDrop ', event);
        event.preventDefault();
        setFile(event.target.files[0]);
        const fname = event.target.files[0].name;
        setImportFileName(fname);
    };

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleDownloadSample = () => {
        window.open('/sample/import_sample.csv', '_blank');
    };

    return (
        <div>
            <div className="rounded overflow-hidden">
                <div style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                    <form onSubmit={() => handleSubmit()}>
                        <div className="sticky top-0 bg-slate-100 z-10">
                            <div>
                                <IconButton onClick={() => { updateCsvImportFormOpen(false) }} edge="start" color="inherit" aria-label="close">
                                    <CloseRoundedIcon className="ml-4" />
                                </IconButton>
                            </div>
                            <div className="flex justify-center text-lg font-semibold -mt-5">

                                <span>
                                    Import Master Loan Data
                                </span>
                            </div>
                            <Divider />
                        </div>
                        <div className="flex justify-center mt-4 min-h-[82vh]">
                            <div className="max-w-[550px] min-w-[550px]">
                                {!loading ? (
                                    <>
                                        <div className="bg-white mt-32">
                                            <div className="max-w-md w-full">
                                                <div onClick={handleClick} className="flex justify-center cursor-pointer mx-3 mb-1" onDragOver={handleDragOver}
                                                    onDrop={handleDrop}>
                                                    <div className="border-2 border-blue-500 border-dashed rounded-lg p-8 min-w-[600px]">
                                                        <div>
                                                            <div className="flex justify-center">
                                                                <CloudUploadIcon sx={{ fontSize: 40 }} color="action" />
                                                                <input
                                                                    type="file"
                                                                    accept=".csv, .xlsx"
                                                                    onChange={handleFileChange}
                                                                    className="mb-6 border p-2"
                                                                    ref={hiddenFileInput}
                                                                    style={{ display: "none" }}
                                                                />
                                                            </div>
                                                            {importFileName ? (
                                                                <p className="text-gray-500 text-md mx-10 mt-3 text-center">
                                                                    <span className="text-blue-700">
                                                                        {importFileName}
                                                                    </span>
                                                                </p>
                                                            ) : (
                                                                <p className="text-gray-500 text-md mx-10 mt-3 text-center">
                                                                    Drag your document here,
                                                                    <span className="text-blue-700">
                                                                        or browse your files
                                                                    </span>
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex justify-center">
                                                    <div>
                                                        <p className="text-gray-500 text-sm mx-10 text-center mb-4">
                                                            Supported format .csv .xlsx, max file size 5 MB
                                                        </p>

                                                    </div>
                                                </div>
                                                <div className="flex justify-start">
                                                    <div>
                                                        <p className="text-gray-500 text-sm mx-10 text-left">
                                                            <span className="font-semibold">Step 1: </span> Download a {" "}
                                                            <span className="cursor-pointer text-blue-600" onClick={handleDownloadSample}>
                                                                sample CSV.
                                                            </span>
                                                        </p>
                                                        <p className="text-gray-500 text-sm mx-10 text-left mt-1">
                                                            <span className="font-semibold">Step 2: </span>  Prepare import file.
                                                        </p>
                                                        <p className="text-gray-500 text-sm mx-10 text-left mt-1">
                                                            <span className="font-semibold">Step 3: </span>  Upload file
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div role="status" className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
                                        <svg ariaHidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="sticky bottom-0 bg-slate-100 z-10 min-h-[60px]">
                            <Divider />
                            <div className="mt-3 mb-3">
                                <div className="flex justify-end mr-4">
                                    <div>
                                        <div className="flex">
                                            <button onClick={() => updateCsvImportFormOpen(false)}
                                                className="pulsebuttonwhite mr-3  min-w-[140px]"
                                            >
                                                <span>Cancel</span>
                                            </button>
                                            {disabled ? (
                                                <div
                                                    className="pulsebuttonblue px-3 py-2 mr-1"
                                                    style={{ opacity: 0.5, pointerEvents: 'none' }}
                                                >
                                                    <span>Save</span>
                                                    <span className="mb-1 -mr-4">
                                                        <svg ariaHidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                        </svg>
                                                    </span>

                                                </div>
                                            ) :
                                                (
                                                    <button
                                                        type="submit"
                                                        className="pulsebuttonblue min-w-[140px]"
                                                    >
                                                        Save
                                                    </button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div >
            </div>

        </div>
    );
};

export default CsvUploader;
