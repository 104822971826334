import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import useAppStore from "../store/appStore";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


const schema = yup
    .object({
        name: yup.string().required(),
        email: yup.string().required(),
        // password: yup.string().required(),
        // confirmpassword: yup.string().required(),

    })
    .required();

export default function EditForm({ fid, closeEvent }) {

    const baseURL = process.env.REACT_APP_API_URL;
    const [password, setPassword] = useState(null);
    const { token, updateUsers } = useAppStore();


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        // console.log("FID: ", fid);
        reset(fid)
    }, []);

    const onSubmit = async (data) => {
        // console.log('data ', data);
        // Object.assign(data, { type: type });
        // Object.assign(data, { status: status });
        if (password) {
            Object.assign(data, { password: password });
        }
        createUser(data);
    };

    const createUser = async (data) => {
        let posturl = baseURL + "userregister/" + fid.id;

        await axios
            .put(
                posturl,
                data,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                console.log(res.data);
                updateUsers(res.data);
                closeEvent();
                toast.success('User created successfully!', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    style: {
                        width: '380px',
                    },
                });
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };


    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };


    return (
        <>
            <Box sx={{ m: 2 }} />
            <Typography variant="h5" align="center">
                Edit User
            </Typography>
            <IconButton
                style={{ position: "absolute", top: "0", right: "0" }}
                onClick={closeEvent}
            >
                <CloseIcon />
            </IconButton>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            {...register("name")}
                            name="name"
                            label="Name"
                            size="small"
                            sx={{ marginTop: "30px", minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.name?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("email")}
                            name="email"
                            label="Email"
                            size="small"
                            sx={{ marginTop: "30px", minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.email?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={password}
                            name="password"
                            label="Password"
                            onChange={handlePasswordChange}
                            type="password"
                            size="small"
                            sx={{ minWidth: "100%" }}
                        />
                        {/* <p className="text-sm text-orange-600">{errors.password?.message}</p> */}
                    </Grid>
                    <Grid item xs={6}>
                        <select
                            name="type"
                            {...register("type")}
                            className={`scaleform ${errors.status ? 'border-red-500' : 'border-gray-300'}`}
                        >
                            <option value="Admin">
                                Admin
                            </option>
                            <option value="Agent">
                                Agent
                            </option>
                        </select>
                        <p className="text-sm text-orange-600">{errors.status?.message}</p>
                    </Grid>
                    {/*
                    <Grid item xs={6}>
                        <select
                            name="status"
                            {...register("status")}
                            className={`scaleform ${errors.status ? 'border-red-500' : 'border-gray-300'}`}
                        >
                            <option value="Active">
                                Active
                            </option>
                            <option value="Inactive">
                                Inactive
                            </option>
                        </select>
                        <p className="text-sm text-orange-600">{errors.status?.message}</p>
                    </Grid>
                       */}
                    <Grid item xs={6}>
                        <TextField
                            {...register("phone")}
                            name="phone"
                            label="Phone"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.phone?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("pincode")}
                            name="pincode"
                            label="Pincode"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.pincode?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("koid")}
                            name="koid"
                            label="KOID"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.koid?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("dra")}
                            name="dra"
                            label="DRA"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.koid?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("state")}
                            label="State"
                            name="state"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.state?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("district")}
                            name="district"
                            label="District"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.district?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("address")}
                            name="address"
                            label="Address"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.address?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("name_of_bank")}
                            name="name_of_bank"
                            label="Name of Bank"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.name_of_bank?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("branch_name")}
                            name="branch_name"
                            label="Branch Name"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.branch_name?.message}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register("region")}
                            name="region"
                            label="Region"
                            size="small"
                            type="text"
                            sx={{ minWidth: "100%" }}
                        />
                        <p className="text-sm text-orange-600">{errors.region?.message}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">
                            <Button variant="contained" type="submit">
                                Submit
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}
