import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import useAppStore from "../store/appStore";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function AddForm({ closeEvent }) {
  const [name, setName] = useState("");
  const [koid, setKoid] = useState("");
  const [dra, setDra] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [type, setType] = useState("Agent");
  const baseURL = process.env.REACT_APP_API_URL;
  const { token, updateUsers } = useAppStore();

  const createUser = async (url) => {
    let posturl = baseURL + "register";

    await axios
      .post(
        posturl,
        {
          name: name,
          email: email,
          password: password,
          type: type,
          dra: dra,
          koid: koid,
          password_confirmation: confirmpassword,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (res) {
        console.log(res.data);
        updateUsers(res.data);
        closeEvent();
        toast.success('User created successfully!', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            width: '380px',
          },
        });
      })
      .catch(function (error) {
        console.log("kcheckpost" + error); //return 429
      });
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleKoidChange = (event) => {
    setKoid(event.target.value);
  };

  const handleDraChange = (event) => {
    setDra(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  return (
    <>
      <Box sx={{ m: 2 }} />
      <Typography variant="h5" align="center">
        Add User
      </Typography>
      <IconButton
        style={{ position: "absolute", top: "0", right: "0" }}
        onClick={closeEvent}
      >
        <CloseIcon />
      </IconButton>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            error={false}
            value={name}
            onChange={handleNameChange}
            label="Name"
            size="small"
            sx={{ marginTop: "30px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={false}
            value={koid}
            onChange={handleKoidChange}
            label="KOID"
            size="small"
            sx={{ minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={false}
            value={dra}
            onChange={handleDraChange}
            label="DRA"
            size="small"
            sx={{ minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={false}
            value={email}
            onChange={handleEmailChange}
            label="Email"
            size="small"
            sx={{ minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={false}
            value={password}
            onChange={handlePasswordChange}
            label="Password"
            type="password"
            size="small"
            sx={{ minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={false}
            value={confirmpassword}
            onChange={handleConfirmPasswordChange}
            label="Confirm Password"
            size="small"
            type="password"
            sx={{ minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            <div className="mt-5">
              <Button variant="contained" onClick={createUser}>
                Submit
              </Button>
            </div>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
