import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import AddForm from "./AddForm";
import EditForm from "./EditForm";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useAppStore from "../store/appStore";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function UsersList() {
    const baseURL = process.env.REACT_APP_API_URL;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [formid, setFormid] = useState("");
    const [open, setOpen] = useState(false);
    const [editopen, setEditOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleEditOpen = () => setEditOpen(true);
    const handleClose = () => setOpen(false);
    const handleEditClose = () => setEditOpen(false);
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const { token, userType, users } = useAppStore();
    const [searchQuery, setSearchQuery] = useState("");
    const [emprecord, SetEmprecord] = useState([]);
    const tenHoursAgo = moment().subtract(10, 'hours');
    const [records, setRecords] = useState(users);

    useEffect(() => {
        searchData(searchQuery);
    }, [searchQuery])

    const searchData = (searchQuery) => {
        let filteredData = users;
        if (searchQuery) {
            filteredData = users.filter(usr =>
                usr?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                usr?.koid?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                usr?.type?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                usr?.phone?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                usr?.email?.toLowerCase().includes(searchQuery.toLowerCase())
            )
            // console.log('searchQuery ', filteredData);
            SetEmprecord(filteredData);
        } else {
            SetEmprecord(users);
        }

    }

    useEffect(() => {
        // console.log('Users ');
        if (!token) {
            navigate("/");
        }
    }, []);

    useEffect(() => {
        getUsersCall();
    }, [users]);

    const getUsersCall = () => {
        if (userType === "Admin") {
            let posturl = baseURL + "users";
            getUsers(posturl);
        }
    }

    const getUsers = async (posturl) => {
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                // console.log('Users ', res.data);
                SetEmprecord(res.data);
                setIsLoaded(true);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const deleteUser = (id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'are you sure to delete this Work item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteApi(id)
                },
                {
                    label: 'No',
                }
            ]
        });
    };

    const deleteApi = async (id) => {
        let posturl = baseURL + "users/" + id;
        await axios
            .delete(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(function (res) {
                // console.log(res.data);
                getUsersCall();
                toast.success('User deleted successfully!', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    style: {
                        width: '380px',
                    },
                });
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const editData = (id,
        name,
        email,
        password,
        type,
        status,
        pincode,
        koid,
        dra,
        state,
        district,
        address,
        loanwiser,
        phone,
        region,
        name_of_bank,
        branch_name
    ) => {
        const data = {
            id: id,
            name: name,
            email: email,
            password: password,
            type: type,
            status: status,
            pincode: pincode,
            koid: koid,
            dra: dra,
            state: state,
            district: district,
            address: address,
            loanwiser: loanwiser,
            phone: phone,
            region: region,
            name_of_bank: name_of_bank,
            branch_name: branch_name,
        };
        setFormid(data);
        handleEditOpen();
    };

    return (
        <>
            <ToastContainer />
            <div>
                <Modal
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <AddForm closeEvent={handleClose} />
                    </Box>
                </Modal>
                <Modal
                    open={editopen}
                    // onClose={handleEditClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <EditForm closeEvent={handleEditClose} fid={formid} />
                    </Box>
                </Modal>
            </div>
            {isLoaded && (<>
                <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px" }}>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{ padding: "20px" }}
                    >
                        Users
                    </Typography>
                    <Divider />
                    <Box height={10} />
                    <Stack direction="row" spacing={2} className="my-2 mb-2">
                        <div>
                            <input
                                type="text"
                                placeholder="Search Users"
                                value={searchQuery}
                                onChange={(e) => (setSearchQuery(e.target.value))}
                                className="rounded-full py-1 px-4 border border-solid border-gray-400"
                            />
                        </div>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1 }}
                        ></Typography>
                        <Button
                            variant="contained"
                            endIcon={<AddCircleIcon />}
                            onClick={handleOpen}
                        >
                            Add
                        </Button>
                    </Stack>
                    <Box height={10} />
                    {emprecord.length > 0 && (
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            Name
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            Email
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            KOID
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            DRA
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            Type
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            Bank
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            Branch
                                        </TableCell>
                                        {/* <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            Availability
                                        </TableCell> */}
                                        {/* <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            Created On
                                        </TableCell> */}
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {emprecord
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                >
                                                    <TableCell align="left">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {row.email}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {row.koid}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {row.dra}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {row.type}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {row.name_of_bank}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {row.branch_name}
                                                    </TableCell>
                                                    {/* <TableCell align="left">
                                                        {moment(row?.login_at).isAfter(tenHoursAgo) ? (
                                                            <span className="text-green-600">
                                                                Online
                                                            </span>
                                                        ) : (
                                                            <span className="text-orange-600">
                                                                Offline
                                                            </span>
                                                        )}
                                                    </TableCell> */}
                                                    {/* <TableCell align="left">
                                                        {moment(
                                                            row.created_at
                                                        ).format("MMMM Do YYYY, h:mm:ss a")}
                                                    </TableCell> */}
                                                    <TableCell align="left">
                                                        <Stack
                                                            spacing={2}
                                                            direction="row"
                                                        >
                                                            <EditIcon
                                                                style={{
                                                                    fontSize:
                                                                        "20px",
                                                                    color: "blue",
                                                                    cursor: "pointer",
                                                                }}
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    editData(
                                                                        row.id,
                                                                        row.name,
                                                                        row.email,
                                                                        row.password,
                                                                        row.type,
                                                                        row.status,
                                                                        row.pincode,
                                                                        row.koid,
                                                                        row.dra,
                                                                        row.state,
                                                                        row.district,
                                                                        row.address,
                                                                        row.loanwiser,
                                                                        row.phone,
                                                                        row.region,
                                                                        row.name_of_bank,
                                                                        row.branch_name
                                                                    );
                                                                }}
                                                            />
                                                            <DeleteIcon
                                                                style={{
                                                                    fontSize:
                                                                        "20px",
                                                                    color: "darkred",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    deleteUser(
                                                                        row.id
                                                                    );
                                                                }}
                                                            />
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    <TablePagination
                        rowsPerPageOptions={[100]}
                        component="div"
                        count={emprecord.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </>)}

        </>
    );
}
