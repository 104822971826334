import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GroupIcon from "@mui/icons-material/Group";
import { useNavigate } from "react-router-dom";
import useAppStore from "../store/appStore";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import WebStoriesIcon from '@mui/icons-material/WebStories';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: 1100,
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default function Sidenav() {
    const navigate = useNavigate();
    const { updateToken, userType, dopen } = useAppStore();
    const baseURL = process.env.REACT_APP_API_URL;
    const [path, setPath] = useState('');

    useEffect(() => {
        // Extract the hash from the URL
        const hash = window.location.hash;

        // Remove the leading '#' and set the state
        setPath(hash.replace('#/', ''));
    }, []);

    const logmeout = async () => {
        updateToken('');
        navigate("/");
    };

    return (
        <Drawer variant="permanent" open={dopen}>
            <DrawerHeader></DrawerHeader>
            <hr className="border-gray-200 shadow-md" />
            <List>
                {(userType === "Admin" || userType === "Healer") && (
                    <>
                        <ListItem
                            disablePadding
                            sx={{ display: "block" }}
                            className={`${path === "home" && "shadow-sm bg-gray-200"}`}
                            onClick={() => {
                                navigate("/home");
                            }}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: dopen ? "initial" : "center",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: dopen ? 3 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    <DashboardIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Dashboard"
                                    sx={{ opacity: dopen ? 1 : 0 }}
                                />
                            </ListItemButton>
                        </ListItem>
                    </>
                )}
                {userType === "Admin" && (
                    <>
                        <ListItem
                            disablePadding
                            sx={{ display: "block" }}
                            className={`${path === "master" && "shadow-sm bg-gray-200"}`}
                            onClick={() => {
                                navigate("/master");
                            }}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: dopen ? "initial" : "center",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: dopen ? 3 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    <WebStoriesIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Loan Master"
                                    sx={{ opacity: dopen ? 1 : 0 }}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            disablePadding
                            sx={{ display: "block" }}
                            className={`${path === "loan" && "shadow-sm bg-gray-200"}`}
                            onClick={() => {
                                navigate("/loan");
                            }}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: dopen ? "initial" : "center",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: dopen ? 3 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    <CurrencyRupeeIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Loan Recovery"
                                    sx={{ opacity: dopen ? 1 : 0 }}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            disablePadding
                            sx={{ display: "block" }}
                            className={`${path === "user" && "shadow-sm bg-gray-200"}`}
                            onClick={() => {
                                navigate("/user");
                            }}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: dopen ? "initial" : "center",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: dopen ? 3 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    <GroupIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Users"
                                    sx={{ opacity: dopen ? 1 : 0 }}
                                />
                            </ListItemButton>
                        </ListItem>
                    </>
                )}
                <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={logmeout}
                >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: dopen ? "initial" : "center",
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: dopen ? 3 : "auto",
                                justifyContent: "center",
                            }}
                        >
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Logout"
                            sx={{ opacity: dopen ? 1 : 0 }}
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </Drawer>
    );
}
