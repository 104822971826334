import { useEffect, useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import useAppStore from "../store/appStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TableView from "../components/tableview";
import jsonData from '../../src/iform.json';
import ImportCSVDrawer from "./import/importCSVDrawer";
import MasterDrawer from "./masterDrawer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import KoidAutocomplete from "./autocomplete";


export default function LoanList() {
    const baseURL = process.env.REACT_APP_API_URL;
    const { token, updateMaster, master, userType, updateCsvImportFormOpen, csvImportFormOpen } = useAppStore();
    const { rowSelection, updateRowSelection } = useAppStore();
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const [loanList, setLoanList] = useState([]);
    const [loading, setLoading] = useState(true);
    const masterData = jsonData[0]?.master;
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedKoid, setSelectedKoid] = useState('');

    // useEffect(() => {
    //     console.log('rowSelection ', rowSelection);
    // }, [rowSelection])


    const handleAssignMaster = async () => {
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };

        let data = {
            koid: selectedKoid
        };

        try {
            for (let i = 0; i < rowSelection.length; i++) {
                const recordId = rowSelection[i];
                let endpoint = baseURL + `master/` + recordId;
                let method = "put";

                // Send PUT request for each record
                const { data: responseData } = await axios[method](endpoint, data, { headers });

                // Optionally, handle the response of each request
                console.log(`Record ${recordId} updated successfully: `, responseData);
            }

            // After all records are updated, fetch updated data
            updateRowSelection([]);
            getUsers();

            // Show success message after all requests are completed
            toast.success('All records modified successfully!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                toastId: 'success',
                progress: undefined,
                theme: "light",
                style: {
                    width: '380px',
                },
            });
        } catch (error) {
            handleApiError(error); // Handle errors for the requests
        }
    };

    const handleApiError = (error) => {
        const errorMessage =
            error.status === 401 || error.status === 403 || error.status === 500
                ? error
                : "Sorry....the backend server is down!! Please try again later";

        if (error?.response?.data?.error) {
            // setError('summary', { type: 'custom', message: error.response.data.error });
        } else {
            toast.error(errorMessage, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                style: {
                    width: '380px',
                },
            });
        }

        console.log(error);
    };


    useEffect(() => {
        searchData(searchQuery);
    }, [searchQuery])

    const searchData = (searchQuery) => {
        let filteredData = loanList;
        if (searchQuery) {
            filteredData = loanList.filter(usr =>
                usr?.name_of_bank?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                usr?.branch_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                usr?.koid?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                usr?.sol_id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                usr?.account_no?.toLowerCase().includes(searchQuery.toLowerCase())
            )
            // console.log('searchQuery ', filteredData);
            setRows(filteredData);
        } else {
            setRows(loanList);
        }

    }

    useEffect(() => {
        updateRowSelection([]);
        updateCsvImportFormOpen(false);
        if (!token) {
            navigate("/");
        }
    }, []);

    useEffect(() => {
        if (!csvImportFormOpen && userType == "Admin") {
            getUsers();
        }
    }, [csvImportFormOpen]);

    useEffect(() => {
        if (master) {
            setRows(master);
        }
    }, [master]);

    const getUsers = async () => {
        let posturl = baseURL + "master";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateMaster(res.data);
                setRows(res.data);
                setLoanList(res.data);
                // console.log('Rows ', res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleOpenForm = () => {
        updateCsvImportFormOpen(true);
    }

    const handleSeletedRecord = (newRecord) => {
        setSelectedKoid(newRecord);
    }

    return (
        <>
            <ImportCSVDrawer />
            <MasterDrawer />
            <div className="ml-2 mr-2">

                <div className="flex justify-between mb-3 mt-5">
                    <div className="text-lg">
                        Loan Master
                    </div>
                    {rowSelection?.length > 0 && (
                        <div>
                            <label className="scaleformlabel mb-2">
                                Select KOID
                            </label>
                            <div className='mr-3 mt-2'>
                                <div className="flex">
                                    <KoidAutocomplete handleSeletedRecord={handleSeletedRecord} />
                                </div>
                                <button
                                    onClick={() => handleAssignMaster()}
                                    className="pulsebuttonblue min-w-[140px] mt-3"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    )}
                    <div>
                        <div className="flex justify-end -mb-4">
                            <div className="mr-2 -mt-1">
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Enter KOID or Account"
                                        value={searchQuery}
                                        onChange={(e) => (setSearchQuery(e.target.value))}
                                        className="rounded-full py-1 px-4 border border-solid border-gray-400"
                                    />
                                </div>
                            </div>
                            <div>
                                <button
                                    className="pulsebuttonblue px-3 py-1 mr-1"
                                    onClick={() => handleOpenForm()}
                                >
                                    <AddCircleIcon className="w-5 h-5" />
                                    <span>Import Loan</span>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

                <Divider />
                <Box height={10} />
                {!loading ? (
                    <div className="mt-5">
                        <TableView rows={rows} from="master" masterData={masterData} />
                    </div>
                ) : (
                    <>
                        <Paper
                            sx={{
                                width: "98%",
                                overflow: "hidden",
                                padding: "12px",
                            }}
                        >
                            <Box height={20} />
                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={30}
                            />
                            <Box height={40} />
                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={60}
                            />
                            <Box height={20} />
                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={60}
                            />
                            <Box height={20} />
                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={60}
                            />
                            <Box height={20} />
                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={60}
                            />
                            <Box height={20} />
                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={60}
                            />
                            <Box height={20} />
                        </Paper>
                    </>
                )}
            </div>
        </>
    );
}
