import { useEffect, useState } from "react";
import Sidenav from "../components/Sidenav";
import Box from "@mui/material/Box";
import Navbar from "../components/Navbar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import "../Dashboard.css";
import CountUp from "react-countup";
import GroupIcon from "@mui/icons-material/Group";
import useAppStore from "../store/appStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import TourIcon from '@mui/icons-material/Tour';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';


export default function Home() {
    const baseURL = process.env.REACT_APP_API_URL;
    const { token } = useAppStore();
    const [usercount, setUsercount] = useState(0);
    const [sanctionAmount, setSanctionAmount] = useState(0);
    const [amountCollected, setAmountCollected] = useState(0);
    const [oustandingBalance, setOustandingBalance] = useState(0);
    const [todayCollection, setTodayCollection] = useState(0);
    const [todayVisit, setTodayVisit] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate("/");
        }
        countUsers();
    }, []);




    const countUsers = async () => {
        let posturl = baseURL + "dashboard";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                // console.log("count: ", res.data);
                setSanctionAmount(res?.data?.total_sanction_amount);
                setUsercount(res?.data?.total_users_count);
                setAmountCollected(res?.data?.total_amount_collected);
                const balance = res?.data?.total_sanction_amount - res?.data?.total_amount_collected;
                setOustandingBalance(balance);
                setTodayCollection(res?.data?.today_collection);
                setTodayVisit(res?.data?.today_visit);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };


    return (
        <>
            <div className="bgcolor">
                <Navbar />
                <Box height={70} />
                <Box sx={{ display: "flex" }}>
                    <Sidenav />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Stack direction="row" spacing={2}>
                                    <Card
                                        className="gradient"
                                        sx={{ minWidth: 49 + "%", height: 140 }}
                                    >
                                        <div className="iconstylewhite">
                                            <GroupIcon />
                                        </div>
                                        <CardContent>
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="div"
                                                sx={{ color: "#f0fcfc" }}
                                            >
                                                <CountUp
                                                    delay={0.2}
                                                    end={usercount}
                                                    duration={0.3}
                                                />
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{ color: "#ccd1d1" }}
                                            >
                                                Total Users
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card
                                        className="gradientlight"
                                        sx={{ minWidth: 49 + "%", height: 140 }}
                                    >
                                        <div className="iconstylewhite">
                                            <CreditScoreIcon />
                                        </div>
                                        <CardContent>
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="div"
                                                sx={{ color: "#f0fcfc" }}
                                            >
                                                <CountUp
                                                    delay={0.2}
                                                    end={sanctionAmount}
                                                    duration={0.3}
                                                />
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{ color: "#ccd1d1" }}
                                            >
                                                Sanction Amount
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card
                                        className="gradientlight"
                                        sx={{ minWidth: 49 + "%", height: 140 }}
                                    >
                                        <div className="iconstylewhite">
                                            <CurrencyRupeeIcon />
                                        </div>
                                        <CardContent>
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="div"
                                                sx={{ color: "#f0fcfc" }}
                                            >
                                                <CountUp
                                                    delay={0.2}
                                                    end={amountCollected}
                                                    duration={0.3}
                                                />
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{ color: "#ccd1d1" }}
                                            >
                                                Total Amount Collected
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Stack>
                            </Grid>
                        </Grid>
                        <div className="mt-5">
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <Stack direction="row" spacing={2}>
                                        <Card
                                            className="gradientlight"
                                            sx={{ minWidth: 49 + "%", height: 140 }}
                                        >
                                            <div className="iconstylewhite">
                                                <CreditScoreIcon />
                                            </div>
                                            <CardContent>
                                                <Typography
                                                    gutterBottom
                                                    variant="h5"
                                                    component="div"
                                                    sx={{ color: "#f0fcfc" }}
                                                >
                                                    <CountUp
                                                        delay={0.2}
                                                        end={oustandingBalance}
                                                        duration={0.3}
                                                    />
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{ color: "#ccd1d1" }}
                                                >
                                                    Outstanding Balance
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                        <Card
                                            className="gradient"
                                            sx={{ minWidth: 49 + "%", height: 140 }}
                                        >
                                            <div className="iconstylewhite">
                                                <ShoppingBagIcon />
                                            </div>
                                            <CardContent>
                                                <Typography
                                                    gutterBottom
                                                    variant="h5"
                                                    component="div"
                                                    sx={{ color: "#f0fcfc" }}
                                                >
                                                    <CountUp
                                                        delay={0.2}
                                                        end={todayCollection}
                                                        duration={0.3}
                                                    />
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{ color: "#ccd1d1" }}
                                                >
                                                    Today&apos;s Collection
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                        <Card
                                            className="gradientlight"
                                            sx={{ minWidth: 49 + "%", height: 140 }}
                                        >
                                            <div className="iconstylewhite">
                                                <TourIcon />
                                            </div>
                                            <CardContent>
                                                <Typography
                                                    gutterBottom
                                                    variant="h5"
                                                    component="div"
                                                    sx={{ color: "#f0fcfc" }}
                                                >
                                                    <CountUp
                                                        delay={0.2}
                                                        end={todayVisit}
                                                        duration={0.3}
                                                    />
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{ color: "#ccd1d1" }}
                                                >
                                                    Today&apos;s Visit
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </Box>
            </div>
        </>
    );
}
