import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from "axios";
import useAppStore from '../store/appStore';


export default function KoidAutocomplete({ handleSeletedRecord, koid }) {
    const { token } = useAppStore();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const baseURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        // console.log('Autocomplete ')
        getUsers();
    }, []);

    const getUsers = async () => {
        let posturl = baseURL + "users";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setUsers(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleDataChange = (event, value) => {
        handleSeletedRecord(value);
    };

    return (
        <div>
            {!loading && (
                <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={users.map((option) => option.koid)}
                    size="small"
                    value={koid}
                    style={{ minWidth: 550 }}
                    getOptionLabel={(option) => {
                        const user = users.find((u) => u.koid === option);
                        return user ? `${user.koid} - ${user.name} / ${user.name_of_bank} / ${user.branch_name}` : option;
                    }}
                    onChange={handleDataChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="KOID"
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                            }}
                        />
                    )}
                />
            )}
        </div>
    );
}

